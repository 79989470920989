<template>
  <div class="container phototext">
    <div class="row">
      <div :class="positionclass" v-if="position=='left' || position=='above'">
        <img class="image" :src="'/img/'+image" :alt="this.alt">
      </div>
      <div :class="positionclass" class="text align-self-center ">
        <slot></slot>
      </div>
      <div class="col" v-if="position=='right' || position=='below'">
        <img class="image" :src="'/img/'+image" :alt="this.alt">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'phototext',
  props: {
    image: {default: "placeholder-image.jpg", type: String},
    alt: {default: "placeholder image", type: String},
    position: {default: "left", type: String},
    size: {default: "large", type: String}
  },
  computed:{
    positionclass: function() {
      return this.position==="above" || this.position ==="below" ? "col-12":"col";
    }
  }
}
</script>
<style scoped>
.phototext {
  margin: 20px 0 30px 0;
}

.image {
  width: 400px;
  height: auto;
  max-width: 90%;
}

.text {
  font-size: 20px;
  height:100%;
}
</style>
