<template>
  <div id="facilities">
    <h2>Facilities</h2>
    <PhotoText image="jumparena.jpg" alt="Photo of jump arena">
      Large 70 x 40m purpose built jumping arena, complete with irrigation, flood lights and a full set of  show jumps.
    </PhotoText>
    <PhotoText image="dressagearena.jpg" alt="Photo of Dressage arena" position="right">
      Full Olympic sized irrigated dressage arena under lights.
    </PhotoText>
    <PhotoText  alt="Photo of tack up shed">
      Covered tack area – includes 6 cross ties, fully rubber lined and under lights
    </PhotoText>
    <PhotoText  alt="Photo of wash bays" position="right">
      Two separate wash bay areas, with pivoting and extendable hose rack and hot/cold water under lights.
    </PhotoText>
    <PhotoText  alt="Photo of stables">
      Separate stable with adjoining yards, rubber lined with lights and power
    </PhotoText>
    <PhotoText  alt="Photo of Round yard" position="right">
      Large irrigated and lined sand round yard under lights.
    </PhotoText>
    <PhotoText  alt="Photos of security cameras and pathway lights">
      Safe and secure property, under 24/7 surveillance with numerous lights to ensure safe access after dark.
    </PhotoText>
    <PhotoText  alt="Photo of Treadmill" position="right">
      Top of the range Equine Treadmill for horse fitness
    </PhotoText>
    <PhotoText  alt="Photo of foot spa">
      Foot spa for applying medical hoof treatments and softening summer hardened hooves
    </PhotoText>
    <PhotoText  alt="Photo of Crush" position="right">
      For examining, treating or weighing horses. The doors can open front and rear. Solid steel construction.
    </PhotoText>
    <PhotoText  alt="Photo of sauna">
      Equi Sauna – Premium RED LIGHT and NEAR INFRARED heat lamps equine sauna/solarium.
      Regular use of this solarium therapy results in improvements in performance and fertility.
    </PhotoText>
  </div>
</template>

<script>
import PhotoText from './PhotoText.vue'
import Heading from './Heading.vue'

export default {
  name: 'facilities',
  components: {
    PhotoText,Heading
  },
  props: {
  }
}
</script>
<style>
#facilities{

}
</style>
