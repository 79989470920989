<template>
  <div class="intro">
  </div>
</template>

<script>
export default {
  name: 'home',
  props: {
  }
}
</script>
<style scoped>
.intro{

}
</style>
