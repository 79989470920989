<template>
  <div id="services">
    <h2>Services</h2>
    <Heading title="Transport"></Heading>
      <PhotoText alt="Photo of truck">
Transport to/from shows in our transport truck. 9 horse, comfort air ride suspension, full rubber drop movable dividers.
      </PhotoText>
    <Heading title="Starting">Horse starting (AKA Breaking)</Heading>
<PhotoText alt="Photo of Ryan breaking" position="below">
Quiet, calm and sensitive starting for your horse. Horses are trained holistically, in addition to standard ‘breaking in’ we also train to stand for farrier and dentist, load onto a float, tie up and be washed as standard. Each horse is treated as an individual and  we believe that each horse must be happy and confident in their work and training them the right way from the very beginning sets them up for a happy life and they’ll always find a great home.</PhotoText>

    <Heading title="Schooling"></Heading>
    <PhotoText alt="Photo of Ryan schooling" position="below">
      Further schooling and education of horses done in a sympathetic and calm atmosphere. Horses can be schooled from the breaking stage through to advanced level, depending on the needs of the horse and requirements of the owner</PhotoText>

    <Heading title="Re-education and problem solving"></Heading>
    <PhotoText alt="photo of Ryan on difficult horse" position="below">
Horse re-education and problem solving. We have a special interest in working with hot, sensitive and reactive horses. If you are having behavioural difficulties with your horse, please contact us to see how we can assist. We successfully work through issues such as rearing, bucking, bolting, biting, kicking and float loading.
    </PhotoText>

      <Heading title="Horse fitness & Conditioning"></Heading>
    <PhotoText alt="Photo of horse on treadmill" position="below">
Using our experience with horse fitness and our wonderful facilities, we are able to work with your horse  to maintain fitness during a spell or build a horses fitness when required – such as due to winter or time restraints. We work with a wide range of clients, from Race Horse Pre trainers, Eventers right through to overweight ponies and pony club horses on the fresh spring grass to ensure they are safe for their riders to return to Pony club activities after the winter break.
    </PhotoText>

    <Heading title="Rehabilitation"></Heading>
    <PhotoText alt="Photo of rehabbing horse" position="below">
Please contact us if your horse requires rehabilitation after injury or an extended spell. We work with your Vet or body worker to ensure the rehabilitation program is optimal to encourage a swift return to normal activity.
    </PhotoText>
    <Heading title="Therapy and injury management"></Heading>
    <PhotoText alt="Photo of therapies available" position="below">
Utilising our extensive rehabilitation therapies available, we provide first class care to ensure the best possible outcomes for your horse. From minor ailments like foot abscesses treated in our Equine Foot Spa, through to major surgery recovery and soft tissue rehabilitation with our Equissage, Equi Sauna and visiting Body Worker and Equitape therapy, you can be sure your horse is in the safest hands
    </PhotoText>

    <Heading title="Rider/Handler lessons"></Heading>
    <PhotoText alt="Photo of Ryan teaching" position="above">
Whilst specialising in Show Jumping, we desire to see all horses live happy lives in any chosen discipline. This sees us work with any horse breed and range of riders. Our emphasis is on building a relationship with horses and working in harmony with them to achieve goals. Working with riders to increase their skill in both the saddle and on the ground is something we find very rewarding.
    </PhotoText>

    <Heading title="Horse Campaigning"></Heading>
    <PhotoText alt="Photo at a comp" position="above">
Horses taken in for schooling and campaigning, travelling to competitions with our Team, they are exposed to the competition circuit.
Whether for a week or long-term campaigning for competition or sales, we will work with you to get the best results.
    </PhotoText>
  </div>
</template>
<script>
import PhotoText from './PhotoText.vue'
import Heading from './Heading.vue'
export default {
  name: 'services',
  components: {
    PhotoText,Heading
  },
  props: {}
}
</script>
<style>
#services {

}
</style>
