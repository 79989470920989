<template>
  <div>
  <h3>{{ title}}</h3>
    <div class="subheading">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'heading',
  props: {
    title: {default: "title", type: String},
    subheading: {default: "", type: String},
  }
}
</script>
<style scoped>
.phototext {
  margin: 20px 0 30px 0;
}

.image {
  width: 400px;
  height: auto;
  max-width: 90%;
}

.text {
  font-size: 20px;
  height:100%;
}
</style>
