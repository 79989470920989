<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="">GSH</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler2" aria-controls="navbarToggler2" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler2">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" href="#facilities">Facilities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#services">Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="header">
  <img alt="GSH Logo" src="/img/logo.png" class="logo">
  <h1>Grevillea Sport Horses</h1>
  <p>The art of keeping a horse between you and the ground</p>
  </section>
  <Home/>
  <Facilities/>
  <Services/>
  <Contact/>
  <section class="footer">
    <p>Copyright ©, Grevillea Sport Horses, 2022</p>
  </section>
</template>
<script>
import Home from './components/Home.vue'
import Facilities from './components/Facilities.vue'
import Services from './components/Services.vue'
import Contact from './components/Contact.vue'

export default {
  name: 'App',
  components: {
    Home, Facilities, Services, Contact
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h1{
  font-size:5vw;
}
h2{
  font-size:40px;
  margin-top:80px !important;
  padding-top:2px;
  border-top:6px solid #f8f9f5;
  border-radius:1000px/10px;
}

img.logo {
  max-height: 220px;
  max-width:95%;
}
.footer{

}
</style>
