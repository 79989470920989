<template>
  <div id="contact">
    <h2>Contact</h2>
    <Heading title="Phone">0427 881 555</Heading>
    <Heading title="Email">ryan@grevilleasporthorses.com.au</Heading>
  </div>
</template>

<script>
import PhotoText from './PhotoText.vue'
import Heading from './Heading.vue'

export default {
  name: 'contact',
  components: {
    PhotoText,Heading
  },
  props: {
  }
}
</script>
<style>
#contact{}
</style>
